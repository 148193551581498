import { DoubleRightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Input, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { useNoAuthAccounts } from 'shared/api/useNoAuthAccounts';
import {
  GET_ACCOUNTS,
  GET_FIREBLOCKS_TRANSACTIONS,
} from 'shared/components/Accounts/Accounts.graphql';
import { Loader } from 'shared/components/common/Loader';
import EVM from 'shared/images/exchanges/evm.svg';
import Polygon from 'shared/images/networks/polygon.svg';

function categorizeByDays(objects) {
  const grouped = {};

  objects.forEach(obj => {
    const date = new Date(obj.createdAt);
    const dayKey = date.toISOString().split('T')[0]; // Формат 'год-месяц-день'

    if (!grouped[dayKey]) {
      grouped[dayKey] = [];
    }

    grouped[dayKey].push(obj);
  });

  return grouped;
}

export function convertDateFormat(dateStr: string) {
  // Создаем объект Date из строки в формате 'YYYY-MM-DD'
  const date = new Date(dateStr);

  // Форматируем дату в 'NOVEMBER 11, 2023'
  return date
    .toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
    .toUpperCase();
}

function formatTimestamp(timestamp: number) {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // добавляем 1, так как месяцы начинаются с 0
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const seconds = ('0' + date.getSeconds()).slice(-2);

  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
}

export const formatHash = (str: string) => {
  const firstFive = str.slice(0, 5);
  const lastFour = str.slice(-4);
  return firstFive + '...' + lastFour;
};

export const TransactionsCategoryWrapper = styled.div`
  border: solid 1px #edeff1;
  padding: 20px;
  display: grid;
  border-top: none;
  border-bottom: none;
  grid-template-columns: 1fr;
  width: fit-content;
  margin: auto;
`;

export const TransactionsCategoryWrapperHeader = styled.div`
  color: #657795;
  display: flex;
  padding: 16px 24px;
  font-size: 12px;
  font-style: normal;
  align-items: center;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  border-bottom: solid 1px #edeff1;
  letter-spacing: 0.6px;
  justify-content: center;
  background-color: #fafbfc;
`;

export const TransactionList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TransactionWrapper = styled.div<{ scam?: boolean }>`
  display: grid;
  padding: 16px 24px;
  column-gap: 23px;
  align-items: center;
  grid-template-rows: 1fr;
  grid-template-columns: 140px 180px 125px 100px 1fr 80px 125px 150px;
  background: #fff;
  border-bottom: solid 1px #edeff1;
  opacity: ${({ scam }) => (scam ? 0.4 : 1)};
`;

export const TransactionTime = styled.div`
  font-size: 12px;
  line-height: 1.33333;
  color: rgb(101, 119, 149);
  font-weight: 500;
`;

export const TransactionHashWrapper = styled.a`
  font-size: 12px;
  line-height: 1.33333;
  color: rgb(101, 119, 149);
  font-weight: 500;
`;

export const TransactionFrom = styled.div`
  color: #050f19;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  letter-spacing: normal;
`;

export const TransactionAssetWrapper = styled.div`
  display: flex;
  gap: 10px;

  img {
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #edeff1;
    min-width: 32px;
  }
`;

export const TransactionAsset = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const TransactionAssetId = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100px;
  height: 1.2em;
  white-space: nowrap;
`;

const TransactionPage = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TransactionFiltersWrapper = styled.div`
  margin: 20px auto;
  background: white;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TransactionFilterButton = styled(Button)`
  transition: 0;

  &.ant-btn.ant-btn-sm {
    color: #657795;
    border: 1px solid transparent;
    margin: 0 8px;
    padding: 11px 20px;
    font-size: 16px;
    background: none;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    border-radius: 100px;
    letter-spacing: normal;
    height: auto;
  }

  &.ant-btn-primary.ant-btn.ant-btn-sm:hover {
    color: white;
  }

  &.ant-btn-primary.ant-btn.ant-btn-sm {
    border-color: #5493ff;
    color: #5493ff;
  }
`;

const TRANSACTION_TYPE_MAP = {
  ALL: {
    key: 'ALL',
    name: 'All',
  },
  TYPED_MESSAGE: {
    key: 'TYPED_MESSAGE',
    name: 'Message',
  },
  TRANSFER: {
    key: 'TRANSFER',
    name: 'Transfer',
  },
  APPROVE: {
    key: 'APPROVE',
    name: 'Approve',
  },
  CONTRACT_CALL: {
    key: 'CONTRACT_CALL',
    name: 'Contract',
  },
};

const TRANSACTION_STATUS = {
  COMPLETED: {
    name: 'Completed',
    color: '#42c57a',
  },
  FAILED: {
    name: 'Failed',
    color: '#fc560b',
  },
};

const SCANNER_TYPE_MAP = {
  ETH: {
    link: 'https://etherscan.io/',
    icon: <EVM />,
  },
  MATIC_POLYGON: {
    link: 'https://polygonscan.com/',
    icon: <Polygon />,
  },
};

const TransactionStatus = styled.div`
  display: flex;
  align-items: center;
`;

const StatusCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
`;

const transactions = () => {
  const [categorizedTransactionsByDays, setCategorizedTransactionsByDays] = useState({});
  const [inputValue, setInputValue] = useState('');
  const [transactionType, setTransactionType] = useState(TRANSACTION_TYPE_MAP.ALL.key);
  const [searchParams, setSearchParams] = useSearchParams();
  const { noAuthTokens } = useNoAuthAccounts();
  const { data: accounts } = useQuery(GET_ACCOUNTS, {
    variables: {
      noAuthTokens,
    },
    fetchPolicy: 'network-only', // DONT TOUCH!!!
    nextFetchPolicy: 'cache-first',
  });

  const account = accounts?.accounts.find(
    ({ connection }) => connection.id === searchParams.get('connectionId'),
  ) || { connection: {} };

  const { data: transactions, loading } = useQuery(GET_FIREBLOCKS_TRANSACTIONS, {
    variables: {
      apiKey: account?.connection.apiKey,
      privateKey: account?.connection.apiSecret,
      api: account?.connection.api,
    },
    skip: !account?.connection.apiKey || !account?.connection.apiSecret,
  });

  useEffect(() => {
    if (transactions?.fireblocksTransactions?.transactions.length) {
      const filterValue = inputValue.toLowerCase();
      const result = categorizeByDays(
        transactions?.fireblocksTransactions?.transactions.filter(
          ({ operation, txHash, assetId, sourceAddress, destinationAddress }) => {
            if (filterValue.length) {
              if (
                txHash.toLowerCase().includes(filterValue) ||
                assetId.toLowerCase().includes(filterValue) ||
                sourceAddress.toLowerCase().includes(filterValue) ||
                destinationAddress.toLowerCase().includes(filterValue)
              ) {
                return true;
              }
              return false;
            }

            if (transactionType === TRANSACTION_TYPE_MAP.ALL.key) {
              return true;
            }
            return operation === transactionType;
          },
        ) || [],
      );
      setCategorizedTransactionsByDays(result);
    }
  }, [transactions?.fireblocksTransactions?.transactions.length, inputValue, transactionType]);

  if (loading) {
    return <Loader />;
  }

  return (
    <TransactionPage>
      <TransactionFiltersWrapper>
        {Object.entries(TRANSACTION_TYPE_MAP).map(([key, { name }]) => {
          return (
            <TransactionFilterButton
              type={key === transactionType ? 'primary' : undefined}
              key={key}
              onClick={() => setTransactionType(key)}
            >
              {name}
            </TransactionFilterButton>
          );
        })}
        <Input
          style={{ maxWidth: 390 }}
          placeholder="Filter by hash/token"
          value={inputValue}
          onChange={({ target }) => {
            setInputValue(target.value);
          }}
        />
      </TransactionFiltersWrapper>
      <div>
        {Object.entries(categorizedTransactionsByDays).map(([date, transactions]) => (
          <TransactionsCategoryWrapper key={date}>
            <TransactionsCategoryWrapperHeader>
              {convertDateFormat(date)}
            </TransactionsCategoryWrapperHeader>
            <TransactionList>
              {transactions.map(
                ({
                  txHash,
                  createdAt,
                  operation,
                  sourceAddress,
                  destinationAddress,
                  requestedAmount,
                  assetId,
                  logo_url,
                  status,
                  source,
                  destination,
                  amountUSD,
                  feeCurrency,
                  networkFee,
                }) => {
                  const transactionStatus = TRANSACTION_STATUS?.[status];
                  const scannerLink = SCANNER_TYPE_MAP?.[feeCurrency]?.link;

                  return (
                    <TransactionWrapper key={txHash + createdAt}>
                      <div>
                        <TransactionTime>{formatTimestamp(createdAt)}</TransactionTime>
                        {txHash ? (
                          <TransactionHashWrapper
                            target="_blank"
                            href={`${scannerLink}tx/${txHash}`}
                          >
                            <Tooltip title={txHash}>{formatHash(txHash)}</Tooltip>
                          </TransactionHashWrapper>
                        ) : null}
                      </div>
                      <div>{TRANSACTION_TYPE_MAP?.[operation]?.name || operation}</div>
                      <TransactionStatus>
                        <StatusCircle
                          style={{ background: transactionStatus?.color }}
                        ></StatusCircle>
                        {transactionStatus?.name || status}
                      </TransactionStatus>
                      <div>
                        <div>
                          <TransactionFrom>From</TransactionFrom>
                          <TransactionHashWrapper
                            target="_blank"
                            href={
                              sourceAddress ? `${scannerLink}address/${sourceAddress}` : undefined
                            }
                          >
                            <Tooltip title={sourceAddress || source.type}>
                              {sourceAddress ? formatHash(sourceAddress) : source.name}
                            </Tooltip>
                          </TransactionHashWrapper>
                        </div>
                      </div>
                      <DoubleRightOutlined />
                      <div>
                        <TransactionFrom>To</TransactionFrom>
                        <TransactionHashWrapper
                          target="_blank"
                          href={
                            sourceAddress
                              ? `${scannerLink}address/${destinationAddress}`
                              : undefined
                          }
                        >
                          <Tooltip title={destinationAddress || destination.type}>
                            {destinationAddress ? formatHash(destinationAddress) : destination.name}
                          </Tooltip>
                        </TransactionHashWrapper>
                      </div>
                      <TransactionAssetWrapper>
                        {logo_url ? (
                          <img width={32} height={32} src={logo_url} alt="logo" />
                        ) : (
                          <QuestionCircleOutlined style={{ width: '32px', height: '32px' }} />
                        )}
                        <TransactionAsset>
                          <div>{requestedAmount?.toFixed(2) || '0.00'}</div>
                          <TransactionAssetId>
                            <Tooltip
                              title={
                                <div>
                                  <div>$ {amountUSD?.toFixed(2) || '0.00'}</div>
                                  <div>{assetId}</div>
                                </div>
                              }
                            >
                              {assetId}
                            </Tooltip>
                          </TransactionAssetId>
                        </TransactionAsset>
                      </TransactionAssetWrapper>
                      <Tooltip title={networkFee}>
                        <div>Network fee</div>
                        <div>{networkFee?.toFixed(5)}</div>
                      </Tooltip>
                    </TransactionWrapper>
                  );
                },
              )}
            </TransactionList>
          </TransactionsCategoryWrapper>
        ))}
      </div>
    </TransactionPage>
  );
};

export default transactions;
