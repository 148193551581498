import { atom } from 'recoil';

import type { User } from 'types';

export const userAtom = atom<User>({
  key: 'userAtom',
  default: {
    mode: 'demo',
  },
});
