import { atom } from 'recoil';

export const nftsAtom = atom({
  key: 'nfts',
  default: {},
});

export const nftListAtom = atom({
  key: 'nftList',
  default: [],
});
