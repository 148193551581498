import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useState } from 'react';
import styled from 'styled-components';

import { useAccountAssets } from 'shared/components/Accounts/useAccountAssets';
import { NetworkIcon } from 'shared/components/Networks/NetworkIcon';

const AccountItem = styled.div`
  display: flex;
  flex-direction: row;
  //color: white;
  gap: 20px;
`;

const AccountItemContent = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

const AccountName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 10px 0;
  font-weight: 600;
  max-width: 120px;
`;

const CoinAmount = styled.div`
  padding: 10px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 52px;
`;

const AccountWrapper = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid;
  cursor: pointer;
`;

const AccountHeader = styled.div`
  &:hover {
    opacity: 0.4;
  }
`;

const AccountTotal = styled.div`
  font-size: 14px;
`;
const AccountTotalUsd = styled.div`
  font-size: 14px;
  color: gray;
`;

const SwapButton = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    opacity: 0.4;
  }
`;

const TokensWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

interface Props {
  address: string;
  noAuthToken: string;
  accountId: string;
  tokenId: string;
  name: string;
  accountType: string;
  onSetSwapDrawer: (params: { isOpen: boolean; chain: string; tokenId: string }) => void;
}

export const Account = ({
  address,
  noAuthToken,
  accountId,
  tokenId,
  name: accountName,
  accountType,
}: Props) => {
  const [showList, setShowList] = useState(false);
  const { data } = useAccountAssets({
    account: {
      noAuthToken,
      id: accountId,
      type: accountType,
    },
  });

  const tokens = data?.accountAssets?.tokens?.filter(
    ({ coingecko_id }) => coingecko_id === tokenId,
  );

  if (!data?.accountAssets || !tokens?.length) {
    return <div></div>;
  }

  return (
    <AccountWrapper>
      <AccountHeader onClick={() => setShowList(!showList)}>
        <div style={{ display: 'flex' }}>
          <AccountName>{accountName}</AccountName>
          {showList ? <CaretUpOutlined /> : <CaretDownOutlined />}
        </div>
        <AccountTotal>
          Total: {tokens.reduce((acc, { amount }) => acc + amount, 0).toFixed(2)}
        </AccountTotal>
        <AccountTotalUsd>
          ${tokens.reduce((acc, { totalUsd }) => acc + totalUsd, 0).toFixed(2)}
        </AccountTotalUsd>
      </AccountHeader>
      {showList ? (
        <TokensWrapper>
          {tokens.map(
            ({ logo_url, accountType, coingecko_id, address: tokenAddress, network, amount }) => {
              return (
                <AccountItem key={coingecko_id + network}>
                  <AccountItemContent>
                    <NetworkIcon
                      logo_url={logo_url as string}
                      accountType={accountType as string}
                      network={network}
                      style={{ width: 20 }}
                    />
                    {/*<AccountName>{accountName}</AccountName>*/}
                  </AccountItemContent>
                  <CoinAmount> {amount}</CoinAmount>
                </AccountItem>
              );
            },
          )}
        </TokensWrapper>
      ) : null}
    </AccountWrapper>
  );
};
