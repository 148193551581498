import { atom } from 'recoil';

interface Currency {
  currency: string;
  value: number;
  prevDayValue: number;
}

export const currencyAtom = atom<Currency[]>({
  key: 'currencyAtom',
  default: [],
});

export const cryptoTickersAtom = atom({
  key: 'cryptoTickers',
  default: [],
});

export const selectedCurrencyAtom = atom<string>({
  key: 'selectedCurrencyAtom',
  default: 'USD',
});
