import { atom, selectorFamily } from 'recoil';

interface Props {
  [key: string]: {
    address: string;
    hash: string;
  };
}
export const TransactionsSummaryAtom = atom<Props>({
  key: 'TransactionsSummaryAtom',
  default: {},
});

export const transactionSummaryById = selectorFamily({
  key: 'TransactionSummaryById',
  // get функция принимает ID и возвращает функцию, которая принимает {get} для чтения других атомов или селекторов
  get:
    address =>
    ({ get }) => {
      const transactionsSummary = get(TransactionsSummaryAtom);

      return transactionsSummary[address];
    },
});

export const TokenListAtom = atom({
  key: 'TokenListAtom',
  default: [],
});
