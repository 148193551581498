import { selector, selectorFamily } from 'recoil';

import { accountsAtom } from 'shared/recoil/atoms/accounts';

import type { Account } from 'shared/components/Accounts/Accounts.interfaces';

export const accountsSelector = selector<Account[]>({
  key: 'accountsSelector',
  get: ({ get }) => get(accountsAtom),
  set: ({ set }, data) => set(accountsAtom, data),
});

export const accountByIdSelector = selectorFamily({
  key: 'accountByIdSelector',
  get:
    id =>
    ({ get }) => {
      const account = get(accountsAtom);
      return account?.find(account => account.id === id) || account?.[id];
    },
});
