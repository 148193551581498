import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { useNoAuthAccounts } from 'shared/api/useNoAuthAccounts';
import { GET_ACCOUNTS } from 'shared/components/Accounts/Accounts.graphql';
import { Account } from 'shared/pages/token/Account';
import { accountsSelector } from 'shared/recoil/selectors/accounts';
import { summaryAssetsTableSelector } from 'shared/recoil/selectors/summaryAssets';

export const Wrapper = styled.div`
  background: white;
  padding: 20px;
  border-radius: 20px;
  margin: 20px 0 20px 20px;
  border: 1px solid rgb(237, 239, 241);
  height: fit-content;
`;

const AccountInfoHead = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
`;

const AccountInfoTitle = styled.div`
  width: 120px;
`;

const AccountInfoAmount = styled.div`
  padding-left: 10px;
`;

const UsdTotal = styled.div`
  font-size: 14px;
  color: gray;
`;

interface Props {
  token: string;
}

export const AccountsInfo = ({ token, onSetSwapDrawer }: Props) => {
  const { noAuthTokens } = useNoAuthAccounts();
  const { data: dataAccounts, loading } = useQuery(GET_ACCOUNTS, {
    variables: {
      noAuthTokens,
    },
    fetchPolicy: 'network-only', // DONT TOUCH!!!
    nextFetchPolicy: 'cache-first',
  });
  const [accounts, setAccounts] = useRecoilState(accountsSelector);
  const assets = useRecoilValue(summaryAssetsTableSelector);
  useEffect(() => {
    if (loading || !dataAccounts?.accounts) return;

    setAccounts(dataAccounts?.accounts);
  }, [dataAccounts]);

  const tokenObj = assets.tokens.find(({ coingecko_id }) => coingecko_id === token);

  return (
    <Wrapper>
      <div>
        {tokenObj?.amount > 0 ? (
          <AccountInfoHead>
            <div>
              Total: {tokenObj?.amount?.toFixed(2)} {tokenObj?.token}
            </div>
            <UsdTotal>${tokenObj?.summaryUsd?.toFixed(2)}</UsdTotal>
          </AccountInfoHead>
        ) : null}
        {accounts.map(({ address, id, noAuthToken, name, type }) => (
          <Account
            accountType={type}
            key={address}
            address={address}
            accountId={id}
            noAuthToken={noAuthToken}
            tokenId={token}
            name={name}
            // onSetSwapDrawer={onSetSwapDrawer}
          />
        ))}
      </div>
    </Wrapper>
  );
};
