import { useQuery } from '@apollo/client';
// import { LiFiWidget } from '@lifi/widget';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { GET_TOKEN, GET_TOKEN_CHART } from 'shared/components/Tokens/Token.graphql';
import { AccountsInfo } from 'shared/pages/token/AccountsInfo';
import { ChartDays } from 'shared/pages/token/components/ChartDays';
import { themeAtom } from 'shared/recoil/atoms/theme';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const getOrCreateTooltip = chart => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = data => context => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  // if (tooltip.opacity === 0) {
  //   tooltipEl.style.opacity = 0;
  //   return;
  // }

  const { dataIndex } = tooltip?.dataPoints?.[0] || {};

  const pointData = data.market_caps[dataIndex]?.value || 0;
  const pointData2 = data.total_volumes[dataIndex]?.value || 0;
  const pointData3 = data.prices[dataIndex]?.value || 0;

  const ul = document.createElement('ul');
  ul.className = 'tooltipBody';

  const li1 = document.createElement('li');
  li1.innerHTML = `<span class="label">Price: </span> <span class="black"> $ ${pointData3?.toLocaleString()}</span>`;
  ul.appendChild(li1);

  const li2 = document.createElement('li');
  li2.innerHTML = `<span class="label">Vol: </span> <span class="black"> $ ${pointData2?.toLocaleString()}</span>`;
  ul.appendChild(li2);
  const li3 = document.createElement('li');
  li3.innerHTML = `<span class="label">Market Cap: </span> <span class="black"> $ ${pointData?.toLocaleString()}</span>`;
  ul.appendChild(li3);

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  tooltipEl.innerHTML = '';
  tooltipEl.appendChild(ul);
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = '10px';
  tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
  tooltipEl.style.borderRadius = '3px';
  tooltipEl.style.color = 'white';
  tooltipEl.style.opacity = 1;
  tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.transform = 'translate(-50%, 0)';
  tooltipEl.style.transition = 'all .1s ease';
  tooltipEl.style.width = '100%';
  tooltipEl.style['max-width'] = 'max-content';
  tooltipEl.style['border-radius'] = '8px';
  tooltipEl.style['z-index'] = 1;
};

function transformData(data, days) {
  const labels =
    days > 1
      ? data.prices.map(point => new Date(point.timestamp).toLocaleDateString())
      : data.prices.map(point => {
          const date = new Date(point.timestamp);
          const dateStr = date.toLocaleDateString();
          const timeStr = date.toLocaleTimeString();
          return `${timeStr}`;
        });

  const dates = data.prices.map(point => new Date(point.timestamp));
  const monthYearArray = dates.map(date => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return `${year}-${month}`;
  });

  // Шаг 2: Фильтруйте уникальные комбинации месяца и года
  const uniqueMonthYearArray = monthYearArray.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });

  return {
    labels,
    datasets: [
      {
        label: 'Prices',
        data: data.prices.map(point => point.value),
        fill: true, // Зарисовка области под графиком
        backgroundColor: 'rgba(45, 72, 241, 0.2)', // Этот цвет будет использоваться для зарисовки области под графиком
        borderColor: 'rgba(33, 61, 240, 1)',
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 10,
      },
    ],
  };
}

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  //justify-content: end;
  flex-direction: column;
  width: 100%;

  .chartButtons {
    margin-left: auto;
    opacity: 1 !important;

    .chartButton {
      cursor: pointer;
      background: transparent;
      font-size: 12px;
      line-height: 16px;
      color: rgb(155, 166, 178);
      padding: 6px 8px;
      border: 1px solid transparent;
      border-radius: 6px;

      &.chartButtonActive {
        color: rgb(101, 119, 149);
        border: 1px solid rgb(237, 239, 241);
      }
    }
  }

  ul {
    padding: 0;
    list-style: none;
  }
`;

export const CoinInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(237, 239, 241);
  padding: 0 24px;
  background: white;
  border-radius: 20px;
  margin-top: 40px;

  @media (max-width: 1070px) {
    padding: 0;
  }

  @media (max-width: 1030px) {
    flex-wrap: wrap;
    max-width: 350px;
  }
`;

const CoinInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  @media (max-width: 1030px) {
    width: 170px;
  }
`;

const CoinInfoBlockLabel = styled.span`
  color: rgb(101, 119, 149);
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
`;

const CoinInfoBlockValue = styled.span`
  color: rgb(5, 15, 25);
  font-size: 16px;
`;

const SparklineTitle = styled.div`
  font-size: 30px;
`;

const CoinInfoBlockValueChanges = styled.span``;

const ChangeValue = styled.span`
  color: ${props => (props.value < 0 ? 'red' : 'green')};
`;

export const SparklinesWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  background: white;
  padding: 20px 20px 0;
  border: 1px solid rgb(237, 239, 241);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
`;

const CoinHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;

  & > img {
    width: 30px;
    margin-right: 15px;
  }
`;

const CoinName = styled.span`
  font-size: 20px;
`;

const SparklinesChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const WrapperChartHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SwapButton = styled.button`
  border: 0;
  outline: 0;
  padding: 10px 20px;
  background: #3f51b5;
  border-radius: 8px;
  color: white;
  max-width: 200px;
  width: 100%;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const PageWrapper = styled.div`
  display: flex;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
`;

const WidgetWrapper = styled.div`
  margin-top: 20px;
  margin-right: 20px;

  [id^='widget-relative-container'] {
    border-radius: 20px;
  }
`;
const displayValue = (value: any) => (value > 0 ? `+${value}` : value);

function formatMarketCap(value: any) {
  if (value >= 1e12) {
    return `$${(value / 1e12).toFixed(1)}T`;
  } else if (value >= 1e9) {
    return `$${(value / 1e9).toFixed(1)}B`;
  } else if (value >= 1e6) {
    return `$${(value / 1e6).toFixed(1)}M`;
  } else if (value >= 1e3) {
    return `$${(value / 1e3).toFixed(1)}K`;
  } else {
    return `$${value}`;
  }
}

function formatSupply(value: any, currency: string) {
  if (value >= 1e9) {
    return `${(value / 1e9).toFixed(0)}B ${currency}`;
  } else if (value >= 1e6) {
    return `${(value / 1e6).toFixed(0)}M ${currency}`;
  } else if (value >= 1e3) {
    return `${(value / 1e3).toFixed(0)}K ${currency}`;
  } else {
    return `${value} ${currency}`;
  }
}

const formatPercentage = (percentage: number) => {
  if (percentage > 999 || percentage < -999) {
    return (percentage / 1000).toFixed(1) + 'K%';
  } else {
    return percentage.toFixed(2) + '%';
  }
};

const token = () => {
  const [appTheme, setTheme] = useRecoilState(themeAtom);
  // const [swapDrawer, setSwapDrawer] = useState({
  //   isOpen: false,
  //   chain: '',
  //   tokenId: '',
  // });

  const [days, setDays] = useState<number>(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const { data } = useQuery(GET_TOKEN, {
    variables: {
      id: searchParams.get('token'),
    },
  });

  const { data: dataChart } = useQuery(GET_TOKEN_CHART, {
    variables: {
      id: searchParams.get('token'),
      days,
    },
  });

  const coinMarketChart = dataChart?.coinMarketChart || {
    prices: [],
    market_caps: [],
    total_volumes: [],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler(coinMarketChart),
      },
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Market Data Chart',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      // y: {
      //   grid: {
      //     display: false,
      //   },
      // },
    },
  };

  const tokenByCoingecko = data?.tokenByCoingecko || {
    symbol: '',
    current_price: '',
    atl: '',
    ath: '',
    atl_change_percentage: 0,
    ath_change_percentage: 0,
  };

  const chartData = transformData(coinMarketChart, days);

  return (
    <PageWrapper>
      <AccountsInfo
        token={searchParams.get('token')}
        // onSetSwapDrawer={setSwapDrawer}
      />
      <Wrapper>
        <SparklinesWrapper>
          <WrapperChartHeader>
            <div>
              <CoinHeader>
                <img src={tokenByCoingecko?.logo_url} />
                <CoinName>
                  {tokenByCoingecko?.name}({tokenByCoingecko?.symbol?.toUpperCase()})
                </CoinName>
              </CoinHeader>
              <SparklineTitle>${tokenByCoingecko?.current_price?.toLocaleString()}</SparklineTitle>
            </div>
          </WrapperChartHeader>
          <ChartDays days={days} onSetDays={setDays} />
          <SparklinesChartWrapper>
            <Line options={options} data={chartData} />
          </SparklinesChartWrapper>
        </SparklinesWrapper>
        <CoinInfoWrapper>
          <CoinInfoBlock>
            <CoinInfoBlockLabel>Market Cap Rank</CoinInfoBlockLabel>
            <CoinInfoBlockValue>#{tokenByCoingecko?.market_cap_rank}</CoinInfoBlockValue>
          </CoinInfoBlock>
          <CoinInfoBlock>
            <CoinInfoBlockLabel>Market Cap</CoinInfoBlockLabel>
            <CoinInfoBlockValue>{formatMarketCap(tokenByCoingecko?.market_cap)}</CoinInfoBlockValue>
          </CoinInfoBlock>
          <CoinInfoBlock>
            <CoinInfoBlockLabel>Circulating Supply</CoinInfoBlockLabel>
            <CoinInfoBlockValue>
              {formatSupply(
                tokenByCoingecko?.circulating_supply,
                tokenByCoingecko?.symbol?.toUpperCase(),
              )}
            </CoinInfoBlockValue>
          </CoinInfoBlock>
          <CoinInfoBlock>
            <CoinInfoBlockLabel>1D / 1Y Change</CoinInfoBlockLabel>
            <CoinInfoBlockValueChanges>
              <ChangeValue value={tokenByCoingecko?.price_change_percentage_24h}>
                {displayValue(tokenByCoingecko?.price_change_percentage_24h)}%
              </ChangeValue>{' '}
              /{' '}
              <ChangeValue value={tokenByCoingecko?.price_change_percentage_1y_in_currency}>
                {displayValue(tokenByCoingecko?.price_change_percentage_1y_in_currency)}%
              </ChangeValue>
            </CoinInfoBlockValueChanges>
          </CoinInfoBlock>
          <CoinInfoBlock>
            <CoinInfoBlockLabel>All-time Low</CoinInfoBlockLabel>
            <CoinInfoBlockValue>
              ${tokenByCoingecko?.atl.toLocaleString()}{' '}
              <ChangeValue value={tokenByCoingecko?.atl_change_percentage}>
                {formatPercentage(tokenByCoingecko?.atl_change_percentage)}
              </ChangeValue>
            </CoinInfoBlockValue>
          </CoinInfoBlock>
          <CoinInfoBlock>
            <CoinInfoBlockLabel>All-time High</CoinInfoBlockLabel>
            <CoinInfoBlockValue>
              ${tokenByCoingecko?.ath.toLocaleString()}{' '}
              <ChangeValue value={tokenByCoingecko?.ath_change_percentage}>
                {formatPercentage(tokenByCoingecko?.ath_change_percentage)}
              </ChangeValue>
            </CoinInfoBlockValue>
          </CoinInfoBlock>
        </CoinInfoWrapper>
      </Wrapper>
      {/*<WidgetWrapper>*/}
      {/*  <LiFiWidget*/}
      {/*    appearance={appTheme === 'dark' ? 'dark' : 'light'}*/}
      {/*    integrator="Trackstats"*/}
      {/*    config={{*/}
      {/*      variant: 'expandable',*/}
      {/*      fromChain: swapDrawer.chain,*/}
      {/*      fromToken: swapDrawer.tokenId,*/}
      {/*    }}*/}
      {/*    open={true}*/}
      {/*    hiddenUI="drawerButton"*/}
      {/*  />*/}
      {/*</WidgetWrapper>*/}
    </PageWrapper>
  );
};

export default token;
