import Papa from 'papaparse';

export function combineCSVData(data) {
  // Функция для расчета общей суммы по totalUsd
  const calculateTotalUsd = dataArray =>
    dataArray.reduce((sum, item) => sum + (parseFloat(item.totalUsd) || 0), 0);

  // Сортировка и обработка протоколов
  const sortedProtocols = [...data.protocols].sort((a, b) => b.totalUsd - a.totalUsd);
  const protocolsData = sortedProtocols.map(
    ({ amount, coingecko_id, name, network, priceUsd, protocol, token, totalUsd, type }) => ({
      coingecko_id: coingecko_id?.includes('custom') ? null : coingecko_id,
      name,
      amount,
      priceUsd,
      network,
      totalUsd,
      protocol,
      token,
      type,
    }),
  );
  const protocolsCSV = Papa.unparse(protocolsData);

  // Сортировка и обработка токенов
  const sortedTokens = [...data.tokens].sort((a, b) => b.totalUsd - a.totalUsd);
  const tokensData = sortedTokens.map(
    ({ coingecko_id, protocol, name, amount, priceUsd, network, totalUsd, token, type }) => ({
      coingecko_id: coingecko_id?.includes('custom') ? null : coingecko_id,
      name,
      amount,
      priceUsd,
      network,
      totalUsd,
      protocol,
      token,
      type,
    }),
  );
  const tokensCSV = Papa.unparse(tokensData);

  // Сбор данных о наградах с учетом протокола
  const rewardsData = [];
  sortedProtocols.forEach(({ name: protocolName, rewards }) => {
    if (rewards && rewards.length > 0) {
      rewards.forEach(reward => {
        rewardsData.push({
          protocol: protocolName,
          reward_name: reward.name,
          amount: reward.amount,
          price: reward.price,
          totalUsd: parseFloat(reward.amount) * parseFloat(reward.price),
        });
      });
    }
  });
  const rewardsCSV = Papa.unparse(rewardsData);

  // Расчет итоговых сумм
  const totalTokens = calculateTotalUsd(tokensData);
  const totalProtocols = calculateTotalUsd(protocolsData);
  const totalRewards = calculateTotalUsd(rewardsData);
  const grandTotal = totalTokens + totalProtocols + totalRewards;

  return `TOKENS:\n${tokensCSV}\n\nPROTOCOLS:\n${protocolsCSV}\n\nREWARDS:\n${rewardsCSV}\n\nTOTAL USD: ${grandTotal}\n\n`;
}

export const transactionsToCsv = data => {
  const formattedTransactions = data.map(
    (
      {
        address,
        chainName,
        blockTimestamp,
        txHash,
        transactionCategory,
        transactionType,
        status,
        fromAddress,
        toAddress,
        feeValue,
        usdFee,
        usdTotal,
        sign,
        formattedValue,
        symbol,
        feeCurrency,
        createdAt,
        formattedDate: dateString,
        protocol,
      },
      index,
    ) => {
      const nextTxHash = data[index + 1]?.txHash;
      const prevTxHash = data[index - 1]?.txHash;

      const dateObj = new Date(dateString);

      // Function to add leading zero to numbers less than 10
      function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
      }

      // Function to format the date in DD/MM/YYYY HH:MM:SS format
      function formatDate(date) {
        return (
          [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
          ].join('/') +
          ' ' +
          [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
          ].join(':')
        );
      }

      // Convert and format the date

      return {
        formattedDate: formatDate(dateObj),
        address,
        tx_hash: txHash,
        chainName,
        transactionCategory,
        tx_type: (transactionType || '').toLowerCase(),
        protocol,
        status: status?.toString() === 'true' ? 'completed' : status,
        from: fromAddress,
        to: toAddress,
        network_fee: feeValue,
        network_fee_usd: usdFee,
        // feeCurrency,
        token: symbol,
        token_balance_change: +formattedValue ? `${sign}${formattedValue}` : 0,
        token_balance_change_usd: `${sign}${usdTotal}`,
      };
    },
  );
  // token_changes,
  // token_balance_change,
  return Papa.unparse(formattedTransactions);
};

export function downloadCSV(data: any, filename = 'summary') {
  // Create a Blob from the CSV String
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });

  // Create a link to download the blob
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', filename);

  // Append link to the body
  document.body.appendChild(link);

  // Trigger download
  link.click();

  // Clean up and remove the link
  document.body.removeChild(link);
}

export function convertChartToCSV({ labels, pl, summaryData }) {
  const header = 'datetime,daily,all\n';

  const rows = labels.map((item, index) => {
    const date = new Date(item).toLocaleDateString();
    const all = summaryData[index];
    const daily = pl[index];

    return `${date},${daily},${all}`;
  });

  return header + rows.join('\n');
}
