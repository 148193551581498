import { atom, selector } from 'recoil';

export const accountCardsAtom = atom<{ [key: string]: any }>({
  key: 'accountCards',
  default: {},
  dangerouslyAllowMutability: true,
});

const SUMMARY_INDEX = -1;

export const activeTabAtom = atom<number | null>({
  key: 'activeTab',
  default: SUMMARY_INDEX,
});

export const activeAccountTypeAtom = atom<string>({
  key: 'activeAccountType',
  default: 'youraccounts',
});

export const fierblockAccountsAtom = atom<any>({
  key: 'fierblockAccounts',
  default: {},
});

export const fierblockAccountSelector = selector({
  key: 'fierblockAccountSelector',
  get: ({ get }) => {
    const activeAccountType = get(activeAccountTypeAtom);
    const fireblockAccounts = get(fierblockAccountsAtom);
    const fireblockAccount = Array.from(
      Object.values(fireblockAccounts),
      ({ accounts }) => accounts,
    )
      .flat()
      .find(
        ({ id, ...item }) => id === activeAccountType || item?.allAccountsKey === activeAccountType,
      );

    return fireblockAccount;
  },
});
