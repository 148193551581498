import classNames from 'classnames';

interface Props {
  days: number;
  onSetDays: (value: number) => void;
}

export const ChartDays = ({ days, onSetDays }: Props) => {
  const handleSetDaysCreator = (value: number) => () => onSetDays(value);

  return (
    <div className="chartButtons">
      <button
        onClick={handleSetDaysCreator(1)}
        className={classNames('chartButton', { chartButtonActive: days === 1 })}
      >
        1D
      </button>
      <button
        onClick={handleSetDaysCreator(8)}
        className={classNames('chartButton', { chartButtonActive: days === 7 })}
      >
        1W
      </button>
      <button
        onClick={handleSetDaysCreator(30)}
        className={classNames('chartButton', { chartButtonActive: days === 30 })}
      >
        1M
      </button>
      <button
        onClick={handleSetDaysCreator(365)}
        className={classNames('chartButton', { chartButtonActive: days === 365 })}
      >
        1Y
      </button>
      <button
        onClick={handleSetDaysCreator(370)}
        className={classNames('chartButton', { chartButtonActive: days === 370 })}
      >
        ALL
      </button>
    </div>
  );
};
