import { atom } from 'recoil';

import type { Account } from 'shared/components/Accounts/Accounts.interfaces';

export const accountsAtom = atom<Account[]>({
  key: 'accountsAtom',
  default: [],
});

export const hideZeroTotalAccountsAtom = atom({
  key: 'hideZeroTotalAccountsAtom',
  default: false,
});
