import { atom } from 'recoil';

interface CoingeckoTokensInfo {
  id: string;
  name: string;
  priceUsd: number;
  current_price: number;
  image: string;
  totalUsd: number;
}

export const coingeckoTokenInfoList = atom<CoingeckoTokensInfo[]>({
  key: 'coingeckoTokenInfoList',
  default: [],
});

export const coingeckoCoinList = atom({
  key: 'coingeckoCoinList',
  default: [],
});

export const tokensForFetching = atom({
  key: 'tokensForFetching',
  default: [],
});

export const tokensForFetchingLoading = atom({
  key: 'tokensForFetchingLoading',
  default: false,
});
