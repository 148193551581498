import { selector, selectorFamily } from 'recoil';

import { getBalance } from 'shared/helpers/getBalance';
import { activeAccountTypeAtom } from 'shared/recoil/atoms/accountCards';
import {
  cryptoTickersAtom,
  currencyAtom,
  selectedCurrencyAtom,
} from 'shared/recoil/atoms/currency';
import { summaryAssetsAtom } from 'shared/recoil/atoms/summaryAssetsAtom';
import { coinGeckoIds } from 'shared/utils/currencyConverter';

import { accountByIdSelector, accountsSelector } from './accounts';

import type { BankAccountObject } from 'shared/components/Accounts/AccountGroupedCard';
import type {
  Account,
  AccountAsset,
  AccountAssets,
} from 'shared/components/Accounts/Accounts.interfaces';
import type {
  AccountSummary,
  AccountSummaryAssets,
} from 'shared/components/Summary/Summary.interfaces';

export const summaryAssetsSelector = selector<AccountSummaryAssets>({
  key: 'summarySelector',
  get: ({ get }) => get(summaryAssetsAtom),
  set: ({ set }, data) => set(summaryAssetsAtom, data),
});

const SALTEDGE_MAP: Record<string, string> = {
  BTC: 'bitcoin',
};
const prepareBankAssets = (
  bankAccounts: BankAccountObject[],
  assets: Record<string, AccountSummary>,
  account: Account,
) => {
  bankAccounts?.forEach(({ accounts, provider_name }) => {
    accounts?.forEach(
      ({
        // account_name,
        balance,
        balanceUsd,
        // client_name,
        currency_code,
        // id,
        name,
        nature,
        // updated_at,
      }) => {
        const coingecko_id = SALTEDGE_MAP?.[currency_code] || currency_code;
        const assetKey = coingecko_id || currency_code;
        const total = Number(balanceUsd);
        const asset = assets[assetKey];
        const summaryUsd = asset ? Number(asset.summaryUsd) + total : total;
        const key = account?.id + name;

        assets[assetKey] = {
          protocol: '',
          day_change_percent_usd: 0,
          name: currency_code,
          token: currency_code,
          coingecko_id,
          ...(asset ? asset : {}),
          amount: asset ? Number(asset.amount) + Number(balance) : balance,
          summaryUsd,
          priceUsd: balanceUsd / balance,
          summaryBalanceUsd: asset ? summaryUsd + asset?.summaryBalanceUsd : summaryUsd,
          logo_url: asset?.logo_url ? asset.logo_url : '',
          accountsMap: {
            ...(asset ? asset.accountsMap : {}),
            [key]: {
              key,
              accountName: `${account?.name}(${provider_name})`,
              accountType: nature,
              amount: balance,
              totalUsd: balanceUsd,
            },
          },
        };
      },
    );
  });
};
const prepareAssets = (
  tokens: AccountAsset[],
  assets: Record<string, AccountSummary>,
  account: Account,
): Record<string, AccountSummary> => {
  tokens?.forEach(
    ({
      is_verified,
      name,
      token,
      coingecko_id,
      amount,
      totalUsd,
      priceUsd,
      network,
      logo_url,
      protocol,
      type,
      rewards,
      health_rate,
      day_change_percent_usd,
      borrowed,
      accountType,
    }: AccountAsset) => {
      const assetKey = coingecko_id || `custom-test-id-${network}-${token}`;
      const asset = assets[assetKey];
      const total = Number(totalUsd);
      const key = totalUsd > 0 ? (account?.id || account.name) + network : undefined;
      const price = Number(priceUsd);
      assets[assetKey] = {
        protocol: '',
        name,
        token,
        is_verified,
        priceUsd: price,
        logo_url,
        type,
        ...(asset ? asset : {}),
        day_change_percent_usd:
          day_change_percent_usd && day_change_percent_usd !== 0
            ? day_change_percent_usd
            : asset?.day_change_percent_usd || 0,
        summaryUsd: asset ? Number(asset.summaryUsd) + total : total,
        amount: asset ? Number(asset.amount) + Number(amount) : amount,
        summaryBalanceUsd: asset
          ? getBalance(total, borrowed, rewards) + Number(asset.summaryBalanceUsd)
          : getBalance(total, borrowed, rewards),
        coingecko_id: coingecko_id || `custom-id-${network}-${token}`,
        accountsMap: {
          ...(asset ? asset.accountsMap : {}),
          [key]: {
            connectionId: account?.connection?.id,
            accountType,
            key,
            protocol,
            type,
            accountName: account?.name,
            amount,
            totalUsd: total,
            network,
            rewards: rewards?.map(({ name, amount, price }) => ({
              name,
              amount: +amount,
              price: +price,
            })),
            borrowed: borrowed?.map(({ name, amount, price }) => ({
              name,
              amount: +amount,
              price: +price,
            })),
            health_rate,
          },
        },
      };
    },
  );
  return assets;
};

const prepareProtocolAssets = (
  tokens: AccountAsset[],
  assets: Record<string, AccountSummary>,
  account: Account,
): Record<string, AccountSummary> => {
  tokens?.forEach(
    ({
      name,
      token,
      coingecko_id,
      amount,
      totalUsd,
      priceUsd,
      network,
      logo_url,
      protocol,
      type,
      rewards,
      health_rate,
      borrowed,
      accountType,
      optimized_symbol,
      supply_token_list,
    }: AccountAsset) => {
      const assetKey = protocol;
      const asset = assets[assetKey];
      const key = account?.id + protocol + network + optimized_symbol + type;
      const total = Number(totalUsd);
      const price = Number(priceUsd);

      assets[assetKey] = {
        day_change_percent_usd: 0,
        name,
        token,
        protocol,
        priceUsd: price,
        logo_url,
        type,
        ...(asset ? asset : {}),
        summaryUsd: asset ? Number(asset.summaryUsd) + total : total,
        amount: asset ? Number(asset.amount) + Number(amount) : amount,
        summaryBalanceUsd: asset
          ? getBalance(total, [], rewards) + Number(asset.summaryBalanceUsd)
          : getBalance(total, [], rewards),
        coingecko_id: asset?.coingecko_id
          ? asset?.coingecko_id
          : coingecko_id || `custom-id-${network}-${token}`,
        accountsMap: {
          ...(asset ? asset.accountsMap : {}),
          [key]: {
            connectionId: account?.connection?.id,
            accountType,
            key,
            protocol,
            type,
            accountName: account?.name,
            amount,
            totalUsd: total,
            network,
            rewards: rewards?.map(({ name, amount, price }) => ({
              name,
              amount: +amount,
              price: +price,
              coingecko_id,
            })),
            borrowed: borrowed?.map(({ name, amount, price, coingecko_id }) => ({
              name,
              amount: +amount,
              price: +price,
              coingecko_id,
            })),
            supply_token_list,
            health_rate,
          },
        },
      };
    },
  );
  return assets;
};

export const summaryAssetsTableSelector = selector({
  key: 'summaryAssetsTableSelector',
  get: ({ get }) => {
    const summary = {
      tokens: {} as Record<string, AccountSummary>,
      protocols: {} as Record<string, AccountSummary>,
    };
    const accountAssets = get(summaryAssetsAtom);
    Object.entries(accountAssets)?.forEach(([accountId, { tokens, protocols, bankAccounts }]) => {
      const account =
        (get(accountByIdSelector(accountId)) as Account) || accountAssets?.[accountId];
      prepareAssets(tokens, summary.tokens, account);
      prepareProtocolAssets(protocols, summary.protocols, account);
      prepareBankAssets(bankAccounts, summary.tokens, account);
    });

    return {
      tokens: Object.values(summary.tokens),
      protocols: Object.values(summary.protocols),
    };
  },
});

export const summaryAssetsTotal = selectorFamily({
  key: 'summaryAssetsTotal',
  get:
    (type: 'tokens' | 'protocols' | 'summary') =>
    ({ get }) => {
      const assets = get(summaryAssetsSelector);
      const selectedCurrency = get(selectedCurrencyAtom);
      const exchangeRates = get(currencyAtom);
      const cryptoTickerList = get(cryptoTickersAtom);
      const accountType = get(activeAccountTypeAtom);

      const currencyValue =
        exchangeRates.find(({ currency }) => currency === selectedCurrency)?.value || 1;

      const cryptoCurrencyValue = cryptoTickerList.find(
        rate => selectedCurrency === coinGeckoIds[rate.name],
      )?.price;

      const assetList = Object.values(assets) as AccountAssets[];
      const accounts = get(accountsSelector);
      const totalUsd = assetList
        .flat()
        .map(({ total }) => total[type] || 0)
        .reduce((acc, sum) => acc + sum, 0);

      const isCalculating =
        accountType !== 'youraccounts'
          ? false
          : assetList?.length === 0 ||
            assetList?.length < accounts?.filter(({ type }) => type !== 'fierblocks').length;
      return {
        isCalculating,
        total: parseFloat(
          cryptoCurrencyValue
            ? (totalUsd / cryptoCurrencyValue).toFixed(2)
            : (totalUsd * currencyValue).toFixed(2),
        ).toLocaleString(),
        selectedCurrency,
        totalUsd,
      };
    },
});
