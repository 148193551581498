import { page } from 'shared/libs/page';

const routes = [
  page('transactions', 'transactions/transactions'),
  page('/testip', 'testip'),
  page('/portfolio', 'home/home'),
  page('/demo', 'demo/demo'),
  page('/', 'landing/landing'),
  page(`/token`, 'token/token'),
  page('/swap', 'swap/swap'),
  // page('/accounts/new', 'accounts/new', getExchangesList),
  page('/accounts/new', 'connectAssets/connectAssets'),
  page('*', 'page-not-found', undefined, false),
] as const;

export default routes;
