interface Rate {
  currency: string;
  value: number;
  prevDayValue: number;
}

interface Params {
  cryptoToUSD: number;
  exchangeRates: Rate[];
  targetCurrency?: string;
  cryptoRates: [];
}

export function convertRatesToUSD(rates: Rate[]): Rate[] {
  // Найдем курс доллара относительно евро
  const usdRate = rates.find(rate => rate.currency === 'USD');
  if (!usdRate) {
    throw new Error('USD rate is not provided in the rates array.');
  }

  // Конвертируем курс евро к доллару
  const eurToUSD = 1 / usdRate.value;
  const eurToUSDprev = 1 / usdRate.prevDayValue;

  const convertedRates = rates.map(rate => {
    if (rate.currency === 'EUR') {
      return {
        currency: 'EUR',
        value: eurToUSD,
        prevDayValue: eurToUSDprev,
      };
    } else if (rate.currency === 'USD') {
      return {
        currency: 'USD',
        value: 1,
        prevDayValue: 1,
      };
    } else {
      return {
        currency: rate.currency,
        value: rate.value * eurToUSD,
        prevDayValue: rate.prevDayValue * eurToUSD,
      };
    }
  });

  return convertedRates;
}

export const coinGeckoIds = {
  bitcoin: 'BTC',
  ethereum: 'ETH',
  binancecoin: 'BNB',
  stellar: 'XLM',
  chainlink: 'LINK',
  'yearn-finance': 'YFI',
};

export function convertCryptoPrice({
  cryptoToUSD,
  exchangeRates,
  targetCurrency,
  cryptoRates = [],
}: Params) {
  const foundUSD = exchangeRates?.find(rate => rate?.currency === 'USD');
  const eurToUSD = foundUSD?.value ? 1 / foundUSD?.value : 1;

  const cryptoToEUR = cryptoToUSD / eurToUSD;

  if (targetCurrency) {
    // Если указана целевая валюта, возвращаем только одно значение
    const targetRate = exchangeRates.find(rate => rate.currency === targetCurrency);
    if (!targetRate) {
      const targetCryptoRate = cryptoRates.find(rate => {
        return targetCurrency === coinGeckoIds[rate.name];
      }) || { price: 1 };

      return {
        currency: targetCurrency,
        value: cryptoToUSD / targetCryptoRate?.price,
      };
    }
    return {
      currency: targetCurrency,
      value: cryptoToEUR * targetRate.value,
    };
  }

  // Иначе возвращаем цены для всех валют
  return exchangeRates.map(rate => ({
    currency: rate.currency,
    value: cryptoToEUR * rate.value,
  }));
}

interface Props {
  cryptoPriceTodayUSD: number;
  percentageChangeUSD: number;
  rateToday: number;
  rateYesterday: number;
}

export function calculatePercentageChangeInCurrency({
  cryptoPriceTodayUSD,
  percentageChangeUSD,
  rateToday,
  rateYesterday,
}: Props) {
  // Вычисляем вчерашнюю цену в долларах на основе сегодняшней цены и процентного изменения
  const cryptoPriceYesterdayUSD = cryptoPriceTodayUSD / (1 + percentageChangeUSD / 100);

  // Конвертируем эту цену в другую валюту по вчерашнему курсу
  const cryptoPriceYesterdayInCurrency = cryptoPriceYesterdayUSD / rateYesterday;

  // Конвертируем сегодняшнюю цену в другую валюту по сегодняшнему курсу
  const cryptoPriceTodayInCurrency = cryptoPriceTodayUSD / rateToday;

  // Вычисляем процентное изменение в другой валюте
  return (
    ((cryptoPriceTodayInCurrency - cryptoPriceYesterdayInCurrency) /
      cryptoPriceYesterdayInCurrency) *
    100
  );
}

export const currencyNames = {
  USD: 'United States Dollar',
  JPY: 'Japanese Yen',
  BGN: 'Bulgarian Lev',
  CZK: 'Czech Koruna',
  DKK: 'Danish Krone',
  GBP: 'British Pound Sterling',
  HUF: 'Hungarian Forint',
  PLN: 'Polish Zloty',
  RON: 'Romanian Leu',
  SEK: 'Swedish Krona',
  CHF: 'Swiss Franc',
  ISK: 'Icelandic Króna',
  NOK: 'Norwegian Krone',
  TRY: 'Turkish Lira',
  AUD: 'Australian Dollar',
  BRL: 'Brazilian Real',
  CAD: 'Canadian Dollar',
  CNY: 'Chinese Yuan',
  HKD: 'Hong Kong Dollar',
  IDR: 'Indonesian Rupiah',
  ILS: 'Israeli New Sheqel',
  INR: 'Indian Rupee',
  KRW: 'South Korean Won',
  MXN: 'Mexican Peso',
  MYR: 'Malaysian Ringgit',
  NZD: 'New Zealand Dollar',
  PHP: 'Philippine Peso',
  SGD: 'Singapore Dollar',
  THB: 'Thai Baht',
  ZAR: 'South African Rand',
  EUR: 'Euro',
  BTC: 'Bitcoin',
  ETH: 'Ether',
  // LTC: 'Litecoin',
  // BCH: 'Bitcoin Cash',
  BNB: 'Binance Coin',
  // EOS: 'EOS',
  // XRP: 'XRP',
  XLM: 'Lumens',
  LINK: 'Chainlink',
  // DOT: 'Polkadot',
  YFI: 'Yearn.finance',
};

export const suggestedCurrency = ['USD', 'EUR', 'BTC', 'ETH'];

export const cryptocurrencies = ['BNB', 'XLM', 'LINK', 'YFI'];

interface CryptoRate {
  priceTodayUSD: number;
  percentageChangeUSD: number;
}

export function calculateRelativeChange(cryptoA: CryptoRate, cryptoB: CryptoRate): number {
  // Вычисляем прошлую цену для каждой криптовалюты
  const previousPriceCryptoA = cryptoA.priceTodayUSD / (1 + cryptoA.percentageChangeUSD / 100);
  const previousPriceCryptoB = cryptoB.priceTodayUSD / (1 + cryptoB.percentageChangeUSD / 100);

  // Вычисляем прошлый и текущий курс обмена между криптовалютами
  const oldRatio = previousPriceCryptoA / previousPriceCryptoB;
  const newRatio = cryptoA.priceTodayUSD / cryptoB.priceTodayUSD;

  return ((newRatio - oldRatio) / oldRatio) * 100;
}
